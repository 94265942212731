<template>
    <tr :class="{ 'border-2 border-red-400': warningBorder }">
        <td :colspan="1">
            <AccountsDropdown
                ref="accountsDropdown"
                :company-type="companyType"
                :pre-selected-account="selectedAccount"
                :disabled="voucherRow.readOnly"
                @selected="accountSelected"
                @blur="selectBlur"
                @enterKeyPressed="enterPressedFromAccountsDropDown"
            />
        </td>
        <td v-if="periodize">
            <DateInputField placeholder="" :disabled="!isAccruable" :pre-selected-value="voucherRow.startDate" @change="updateStartDate($event), updateValues()" />
        </td>
        <td v-if="periodize">
            <DateInputField placeholder="" :disabled="!isAccruable" :pre-selected-value="voucherRow.endDate" @change="(endDate = $event), updateValues()" />
        </td>
        <td v-if="writeComments">
            <input @keydown.enter="enterPressed" @keyup="updateValues" v-model="comment" :disabled="voucherRow.readOnly" class="w-full text-sm py-1 px-2" />
        </td>
        <td>
            <InputCurrency
                ref="debitInput"
                v-model="debit"
                custom-class="w-full text-sm py-1 px-2 text-right"
                :disabled="disableDebitInputCurrency"
                @focus="debitInputFocus = true"
                @blur="debitInputFocus = false"
                @keyPressed="keyPressed"
                @keyUp="updateValues"
            />
        </td>
        <td>
            <InputCurrency
                ref="creditInput"
                v-model="credit"
                custom-class="w-full text-sm py-1 px-2 text-right"
                :disabled="disableCreditInputCurrency"
                @focus="creditInputFocus = true"
                @blur="creditInputFocus = false"
                @keyPressed="keyPressed"
                @keyUp="updateValues"
            />
        </td>
        <td class="w-12 px-0" align="right">
            <span v-if="showDeleteBtn" class="mr-4 cursor-pointer" @click="removeEntry" :disabled="canDelete || voucherRow.readOnly" tabindex="-1">
                <i class="fa-regular fa-trash-can mt-2" />
            </span>
        </td>
    </tr>
</template>
<script>
import AccountsPlanItems from "./AccountsPlanItems.vue";
import moment from "moment";

export default {
    props: {
        accountPlanItems: {
            type: Array,
            default: () => [],
        },
        companyType: {
            type: Number,
            default: 0,
        },
        debitSummary: {
            type: Number,
            default: 0,
        },
        creditSummary: {
            type: Number,
            default: 0,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        warningBorder: {
            type: Boolean,
            default: false,
        },
        voucherRow: {
            type: Object,
            default: () => {},
        },
        writeComments: {
            type: Boolean,
            default: true,
        },
        periodize: {
            type: Boolean,
            default: false,
        },
        financialYears: {
            type: [],
            default: false,
        },
    },

    data() {
        return {
            selectedAccount: null,
            comment: "",
            debit: null,
            credit: null,
            debitInputFocus: false,
            creditInputFocus: false,
            startDate: null,
            endDate: null,
            categoryId: null,
        };
    },

    watch: {
        voucherRow: {
            immediate: true,
            deep: true,
            async handler(newValue) {
                if (newValue) {
                    if (newValue.credit === 0) {
                        this.credit = null;
                    } else {
                        this.credit = newValue.credit;
                    }
                    if (newValue.debit === 0) {
                        this.debit = null;
                    } else {
                        this.debit = newValue.debit;
                    }
                    this.selectedAccount = newValue.account;
                    this.comment = newValue.text;
                    this.startDate = newValue.startDate;
                    this.endDate = newValue.endDate;
                    this.categoryId = newValue.categoryId;
                }
            },
        },
    },

    components: {
        AccountsDropdown: AccountsPlanItems,
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    created() {
        // If user will click Vänd button, do not select dropdown with accounts
        if (this.voucherRow.id !== 999999) return;
    },

    computed: {
        isAccruable() {
            if (this.voucherRow.account == 2641 && this.voucherRow.autoGenerated) return true;
            if (this.voucherRow.readOnly && !this.voucherRow.categoryId) return false;
            if (this.accountPlanItems && this.selectedAccount) {
                const result = this.accountPlanItems.find(account => account.value === this.selectedAccount).accruable;
                return result;
            } else return false;
        },

        mergeColumns() {
            return this.writeComments ? 1 : 2;
        },

        bookingAccount() {
            if (this.voucherRow.readOnly || !this.periodize || !this.startDate || !this.endDate) {
                return this.selectedAccount;
            }
            return null;
        },

        showDeleteBtn() {
            if (this.voucherRow.categoryId != null) {
                return true;
            }
            if (this.voucherRow.readOnly) {
                return false;
            }
            if (!this.creditInputFocus && !this.credit && !this.debit && !this.selectedAccount) {
                return false;
            }

            return true;
        },

        disableCreditInputCurrency() {
            if (this.debit) {
                return true;
            } else if (this.voucherRow.categoryId != null) {
                return false;
            } else if (this.voucherRow.readOnly) {
                return true;
            }
            return false;
        },

        disableDebitInputCurrency() {
            if (this.credit) {
                return true;
            } else if (this.voucherRow.categoryId != null) {
                return false;
            } else if (this.voucherRow.readOnly) {
                return true;
            }
            return false;
        },
    },

    methods: {
        updateStartDate(startDate) {
            if (!startDate) return;
            let inOpenYear = false;
            const momentStartDate = moment(startDate);
            this.financialYears.forEach(financialYear => {
                const momentFStartDate = moment(financialYear.startDate);
                const momentFEndDate = moment(financialYear.endDate);
                if (momentStartDate.isBetween(momentFStartDate, momentFEndDate, "day", "[]")) {
                    inOpenYear = true;
                }
            });
            const today = moment();
            if (momentStartDate.diff(today, "year") >= 1) {
                this.$toasted.show("WARNING: Startdatum är mär än ett år framåt", {
                    theme: "bubble",
                    duration: 7000,
                    position: "bottom-center",
                    type: "warning",
                    keepOnHover: true,
                });
            } else if (!inOpenYear && !momentStartDate.isAfter(today)) {
                this.$toasted.show("Startdatum ingår inte i ett öppet räkneskapsår", {
                    theme: "bubble",
                    duration: 4000,
                    position: "bottom-center",
                    type: "error",
                    keepOnHover: true,
                });
                this.startDate = "";
            } else {
                this.startDate = startDate;
            }
        },

        updateValues() {
            this.$emit("updateValues", {
                account: this.selectedAccount,
                debit: this.debit || 0,
                credit: this.credit || 0,
                comment: this.comment,
                startDate: this.startDate || null,
                endDate: this.endDate || null,
                categoryId: this.categoryId || null,
                autoGenerated: this.voucherRow.autoGenerated,
                interim: this.voucherRow.interim,
                readOnly: this.voucherRow.readOnly,
            });
        },

        selectBlur(status) {
            if (!status && !this.selectedAccount && (this.credit || this.debit)) {
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.blur();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
            }
        },

        accountSelected(account) {
            this.selectedAccount = account.value;
            if (account.accruable == false) {
                this.startDate = "";
                this.endDate = "";
                this.voucherRow.startDate = "";
            }
            this.updateValues();
            if (this.debit || this.credit) {
                this.approve();
                return;
            }

            if (!account.value) return;

            setTimeout(() => {
                this.$refs.debitInput.$refs.inputRef.focus();
            }, 100);
        },

        accruableSelected(value) {
            if (value == false) {
                this.startDate = "";
                this.endDate = "";
                this.voucherRow.startDate = "";
            }
        },

        approve() {
            this.$emit("approve", {
                id: Math.random(),
                account: this.selectedAccount,
                debit: this.debit || 0,
                credit: this.credit || 0,
                comment: this.comment,
                startDate: null,
                endDate: null,
                readOnly: false,
                categoryId: this.categoryId || null,
            });
        },

        keyPressed(e) {
            if (e.key !== "Tab" && e.key !== "Enter") {
                return;
            }

            if (e.key === "Enter" && !this.credit && !this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            // If everything is empty
            if (e.key === "Tab" && this.creditInputFocus && !this.credit && !this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            // If account is selected but fields are empty
            if (e.key === "Tab" && this.creditInputFocus && !this.credit && !this.debit && this.selectedAccount) {
                e.preventDefault();
                this.$refs.debitInput.$refs.inputRef.focus();
                return;
            }

            if (e.key === "Tab" && this.debit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            if (e.key === "Tab" && this.credit && !this.selectedAccount) {
                e.preventDefault();
                this.$refs.accountsDropdown.$refs.dropdown.$refs.searchInput.focus();
                return;
            }

            if (e.key === "Tab" && this.debit && this.selectedAccount) {
                this.approve();
                return;
            }

            if (e.key === "Tab" && this.credit && this.selectedAccount) {
                this.approve();
                return;
            }

            if (e.key === "Enter" && !this.credit && !this.debit && this.selectedAccount && (this.debitInputFocus || this.creditInputFocus)) {
                if (this.debitSummary > this.creditSummary) {
                    this.credit = (this.debitSummary - this.creditSummary).roundToEven2();
                    this.$refs.creditInput.$refs.inputRef.focus();
                }
                if (this.creditSummary > this.debitSummary) {
                    this.debit = (this.creditSummary - this.debitSummary).roundToEven2();
                    this.$refs.debitInput.$refs.inputRef.focus();
                }
                this.updateValues();
                return;
            }

            if (e.key === "Enter" && (this.credit || this.debit) && this.selectedAccount && (this.debitInputFocus || this.creditInputFocus) && this.debitSummary === this.creditSummary) {
                this.$emit("createVoucher");
                return;
            }
        },

        enterPressed() {
            if ((this.credit || this.debit) && this.selectedAccount && this.debitSummary === this.creditSummary) {
                this.$emit("createVoucher");
                return;
            }
        },

        enterPressedFromAccountsDropDown() {
            if (this.debitSummary === this.creditSummary) {
                this.$emit("createVoucher");
                return;
            }
        },

        removeEntry() {
            this.$emit("removeEntry");
        },

        showSelectPeriodModal() {
            this.$emit("showSelectPeriodModal", {
                account: this.selectedAccount,
                amount: this.debit || this.credit,
            });
        },
    },
};
</script>

<style scoped>
.fa-trash-can {
    color: salmon;
}
td {
    border: 1px solid #ddd;
}
.el-input__inner {
    border: 0px !important;
}
</style>
